import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SideBar from "../other/SideBar";
import NavBar from "../other/NavBar";
import { GetOrdersURL, ScriptId, ScriptSRC } from "../../assets/api/BaseURL";
import LoadingModal from "../../loader/LoadingModal";
import Notification from "../other/Notification";
import { useToast } from "../../../context/ToastContext";

const Orders = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [token, setToken] = useState("");
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [canvas, setCanvas] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const availableRecordsPerPageOptions = [10, 25, 50, 100];
  const [totalPages, setTotalPages] = useState(1);
  const [totalrecords, setTotalRecords] = useState(0);
  const [pageNumbersBatch, setPageNumbersBatch] = useState([]);
  const [onlySavePaymentRoundUpCheck, setOnlySavePaymentRoundUpCheck] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const handleToggleSidebar = (e) => {
    e.preventDefault();
    setToggleSideBar(!toggleSideBar);
  };

  const handleToggleOffCanvas = (e) => {
    e.preventDefault();
    setCanvas(!canvas);
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const calculateStartSerialNumber = () => {
    return (currentPage - 1) * recordsPerPage + 1;
  };

  const formatTimestamp = (timestampStr) => {
    const timestamp = parseInt(timestampStr, 10);
    const dateObject = new Date(timestamp * 1000);

    const day = dateObject.getDate();
    const month = dateObject.toLocaleString("default", { month: "short" });
    const year = dateObject.getFullYear();
    let hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";

    if (hours > 12) {
      hours -= 12;
    }

    return `${day} ${month} ${year}, ${hours}:${minutes
      .toString()
      .padStart(2, "0")} ${ampm}`;
  };

  const handleDetailsClick = (e, id) => {
    e.preventDefault();
    localStorage.setItem("orderID", id);
    navigate(`/order_details`);
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");


    }
  }, [navigate]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    if (localStorage.getItem('round_off_amount') === "1") {
      setOnlySavePaymentRoundUpCheck(true)
      // alert("dd")

    }
  }, [token]);

  useEffect(() => {
    const existingScript = document.getElementById(ScriptId);
    if (!existingScript && localStorage.getItem("userId")) {
      const userId = localStorage.getItem("userId");
      const script = document.createElement("script");
      script.src = ScriptSRC + userId.replace(/"/g, "");
      script.defer = true;
      script.id = ScriptId;
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("currencySymbol")) {
      setCurrencySymbol(localStorage.getItem("currencySymbol"));
    }
  }, []);

  useEffect(() => {
    if (token) {
      async function getData() {
        if(!searchInput.trim()){
        setIsLoading(true);

        }
        try {
          const response = await axios.get(GetOrdersURL, {
            params: {
              page: currentPage,
              limit: recordsPerPage,
              search:searchInput.trim()
            },
            headers: { authorization: `Bearer ${token}` },
          });

          if (response.status === 200 && response.data.data) {
            setData(response.data.data);
            setTotalRecords(response.data.totalRecord);
          } else if (response.status === 200 && response.data.message) {
            setData([]);
            setTotalRecords(0);
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            addToast("error", error.response.data.message);
          } else {
            addToast("error", error.message);
          }
          console.log(error);
        }
        setIsLoading(false);
      }
      getData();
    }
  }, [token, currentPage, recordsPerPage, addToast, searchInput]);

  useEffect(() => {
    if (totalrecords) {
      const totalPages = Math.ceil(totalrecords / recordsPerPage);
      setTotalPages(totalPages);

      if (currentPage > totalPages) {
        setCurrentPage(totalPages);
      } else if (currentPage < 1) {
        setCurrentPage(1);
      }

      const maxPageNumbers = 1;
      let startPage = Math.max(currentPage - maxPageNumbers, 1);
      let endPage = Math.min(currentPage + maxPageNumbers, totalPages);

      if (endPage - startPage + 1 <= maxPageNumbers * 2) {
        if (startPage === 1) {
          endPage = Math.min(
            endPage + (maxPageNumbers * 2 - endPage + startPage - 1),
            totalPages
          );
        } else if (endPage === totalPages) {
          startPage = Math.max(
            startPage - (maxPageNumbers * 2 - endPage + startPage - 1),
            1
          );
        }
      }

      if (currentPage === 1) {
        endPage = Math.min(3, totalPages);
      }

      if (currentPage === totalPages && totalPages >= 3) {
        startPage = Math.max(currentPage - 2, 1);
        endPage = totalPages;
      }

      const batch = Array.from(
        { length: endPage - startPage + 1 },
        (_, i) => startPage + i
      );
      setPageNumbersBatch(batch);
    }
  }, [currentPage, recordsPerPage, totalrecords, totalPages]);

  function getDecimalPart(number) {
    // Extract the integer part using Math.floor() and subtract it from the original number
    const integerPart = Math.floor(number);
    let decimalPart = number - integerPart;
    // decimalPart = decimalPart.toFixed(2)
    if (decimalPart < 0.5) {
      return -decimalPart
    }
    else {
      return 1 - decimalPart
    }
  }

  return (
    <>
      {token && (
        <>
          <div
            className={
              toggleSideBar
                ? "container-scroller sidebar-icon-only"
                : "container-scroller"
            }
          >
            <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
              <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                <Link className="navbar-brand brand-logo-mini" to="/live_orders">
                  <img src="images/mini-kr-logo.svg" alt="logo" />
                </Link>
                <div className="me-1">
                  <button
                    className={
                      toggleSideBar
                        ? "navbar-toggler navbar-toggler align-self-center"
                        : "navbar-toggler navbar-toggler align-self-center text-white"
                    }
                    type="button"
                    onClick={handleToggleSidebar}
                  >
                    <span className="icon-menu"></span>
                  </button>
                </div>
                <div>
                  <Link to="/live_orders">
                    <span className="navbar-brand brand-logo">
                      <img src="images/Khateraho_logo_white.png" alt="logo" />
                    </span>
                  </Link>
                </div>
              </div>
              <NavBar handleToggle={handleToggleOffCanvas} />
            </nav>
            <div className="container-fluid page-body-wrapper px-0">
              <SideBar active="orders" toggleCanvas={canvas} />

              <div className="main-panel">
                <div className="content-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <h3 className="ukhd mb-3">Orders</h3>
                      <div className="row flex-grow">
                        <div className="col-12 grid-margin stretch-card">
                          <div className="card card-rounded">
                            <div className="card-body">
                              <div className="d-sm-flex justify-content-between align-items-start">
                                <div className="vsalign">
                                  <h4 className="card-title card-title-dash">
                                    All Orders
                                  </h4>
                                  <div>
                                  <label>Show records per page:</label>
                                  <select
                                    value={recordsPerPage}
                                    onChange={(e) =>
                                      setRecordsPerPage(
                                        parseInt(e.target.value)
                                      )
                                    }
                                  >
                                    {availableRecordsPerPageOptions.map(
                                      (option) => (
                                        <option key={option} value={option}>
                                          {option}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>

                                </div>
                                <div className="vsalign">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search Here"
                                    value={searchInput}
                                    onChange={(e) =>
                                      setSearchInput(e.target.value)
                                    }
                                  />

                                </div>
                              </div>
                              <div className="table-responsive mt-1">
                               


                                <table className="table select-table">
                                  <thead>
                                    <tr>
                                      <th>S.No.</th>
                                      <th style={{ width: "15%" }}>Orders</th>
                                      <th>Client Details</th>
                                      <th>Amount</th>
                                      {/* <th>Order Mode</th> */}
                                      <th>Order Status</th>
                                      <th>Payment Status</th>
                                      <th className="text-right">Action</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {data.length === 0 ? (
                                      <tr>
                                        <td colSpan="8" className="text-center">
                                          No data found
                                        </td>
                                      </tr>
                                    ) : (
                                      data.map((item, index) => (
                                        <tr key={item.id}>
                                          <td>
                                            {calculateStartSerialNumber() +
                                              index}
                                          </td>
                                          <td>
                                            <h6>#{item.order_number_qrcode}</h6>
                                            <h6>#{item.order_number}</h6>
                                            {/* <p>{item.order_type} | {item.table_no}</p> */}

                                            {item.order_type == 'dine_in' ? <>

                                              <p
                                                style={{
                                                  display: "block",
                                                }}
                                              >

                                                Dine In(

                                                {item.table_no})
                                              </p>

                                            </> : <></>}

                                            {item.order_type == 'take_away' ? <>
                                              <p
                                                style={{
                                                  display: "block",
                                                }}
                                              >
                                                Take Away

                                              </p>

                                            </> : <></>}

                                            {item.order_type == 'delivery' ? <>

                                              <p
                                                style={{
                                                  display: "block",
                                                }}
                                              >
                                                Delivery

                                              </p>




                                            </> : <></>}
                                            <p>
                                              {formatTimestamp(
                                                item.creation_date
                                              )}
                                            </p>
                                            <p>
                                              Platform - {item.order_mode_label}
                                            </p>
                                          </td>
                                          <td>
                                            <div>
                                              {item.eater_name && (
                                                <h6>
                                                  <span className="mdi mdi-account"></span>
                                                  {item.eater_name}
                                                </h6>
                                              )}

                                              {item.eater_phonenumber && (
                                                <p>
                                                  <span className="mdi mdi-phone"></span>
                                                  {item.eater_phonenumber}
                                                </p>
                                              )}
                                            </div>
                                          </td>
                                          <td>
                                            {item.total !== null && (
                                              <span className="text-danger">
                                                {currencySymbol}{" "}
                                                {/* {parseFloat(item.total).toFixed(
                                                  2
                                                )} */}

                                                {onlySavePaymentRoundUpCheck && item.payment_status === 0 ? <>

                                                  {(parseFloat(item.total) + parseFloat(getDecimalPart(item.total))).toFixed(2)}
                                                </> : <>

                                                  {parseFloat(
                                                    item.total
                                                  ).toFixed(2)}

                                                </>}
                                              </span>
                                            )}
                                          </td>
                                          {/* <td>
                                            <p>{item.order_mode_label}</p>
                                          </td> */}
                                          <td>
                                            {item.status === 0 && (
                                              <div className="badge badge-opacity-secondary">
                                                {item.order_status_lable}
                                              </div>
                                            )}
                                            {item.status === 1 && (
                                              <div className="badge badge-theme">
                                                {item.order_status_lable}
                                              </div>
                                            )}
                                            {item.status === 2 && (
                                              <div className="badge badge-info">
                                                {item.order_status_lable}
                                              </div>
                                            )}
                                            {item.status === 3 && (
                                              <div className="badge badge-opacity-warning">
                                                {item.order_status_lable}
                                              </div>
                                            )}
                                            {item.status === 4 && (
                                              <div className="badge badge-danger">
                                                {item.order_status_lable}
                                              </div>
                                            )}
                                          </td>

                                          <td>
                                            {/* {item.payment_status ? (
                                              <div className="badge badge-info">
                                                {item.payment_status_label}
                                              </div>
                                            ) : (
                                              <div className="badge badge-opacity-danger">
                                                {item.payment_status_label}
                                              </div>
                                            )} */}

                                            {item.payment_status === 0 && (
                                              <div className="badge badge-opacity-danger">
                                                {/* {item.payment_status_label} */}
                                                Unpaid
                                              </div>
                                            )
                                            }

                                            {item.payment_status === 1 && (
                                              <div className="badge badge-opacity-success">
                                                {/* {item.payment_status_label} */}
                                                Paid
                                              </div>
                                            )
                                            }

                                            {item.payment_status === 2 && (
                                              <div className="badge badge-opacity-warning">
                                                {/* {item.payment_status_label} */}
                                                Hold
                                              </div>
                                            )
                                            }

                                            {item.payment_status === 3 && (
                                              <div className="badge badge-dark partially-paid-bg-collor">
                                                {/* {item.payment_status_label} */}
                                                Partially Paid
                                              </div>
                                            )
                                            }

                                            {item.is_cancelled === 1 && (
                                              <div className="badge badge-danger mx-2">
                                                Order Cancelled
                                              </div>
                                            )}
                                          </td>

                                          <td className="text-right pe-0">
                                            <div className="actionli">
                                              <button
                                                type="button"
                                                className="btn btn-info"
                                                onClick={(e) =>
                                                  handleDetailsClick(e, item.id)
                                                }
                                              >
                                                <i className="mdi mdi-content-save-edit-outline vsalign"></i>{" "}
                                                Details
                                              </button>
                                              {/* <br />
                                              <button
                                                type="button"
                                                className="btn btn-warning"
                                              >
                                                <i className="mdi mdi-printer vsalign"></i>{" "}
                                                Bill
                                              </button>
                                              <br />
                                              <button
                                                type="button"
                                                className="btn btn-dark"
                                              >
                                                <i className="mdi mdi-printer vsalign"></i>{" "}
                                                KOT
                                              </button> */}
                                            </div>
                                          </td>
                                        </tr>
                                      ))
                                    )}
                                  </tbody>
                                </table>
                              </div>

                              <div className="row">
                                <div className="col-6">
                                  {data.length > 0 && (
                                    <p>
                                      Showing{" "}
                                      {(currentPage - 1) * recordsPerPage + 1}{" "}
                                      to{" "}
                                      {(currentPage - 1) * recordsPerPage +
                                        data.length}{" "}
                                      of {totalrecords} entries
                                    </p>
                                  )}
                                </div>
                                <div className="col-6">
                                  <div className="pagination">
                                    <button
                                      onClick={handlePrevPage}
                                      disabled={currentPage === 1}
                                      className={
                                        currentPage === 1 ? "disabled" : ""
                                      }
                                    >
                                      {`<<`}
                                    </button>
                                    {pageNumbersBatch.map((pageNumber) => (
                                      <button
                                        key={pageNumber}
                                        onClick={() =>
                                          handlePageClick(pageNumber)
                                        }
                                        className={
                                          pageNumber === currentPage
                                            ? "active"
                                            : ""
                                        }
                                      >
                                        {pageNumber}
                                      </button>
                                    ))}
                                    <button
                                      onClick={handleNextPage}
                                      disabled={currentPage === totalPages}
                                      className={
                                        currentPage === totalPages
                                          ? "disabled"
                                          : ""
                                      }
                                    >
                                      {`>>`}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <LoadingModal isLoading={isLoading} />

          <Notification />
        </>
      )}
    </>
  );
};

export default Orders;
